<template>
  <div class="box">
    <el-form :model="form" :label-width="labelWidth" ref="searchForm" class="innerForm">
      <el-row :gutter="20">
        <!--        <el-col :span="6">-->
        <!--          <el-form-item label="实际开始时间" prop="startTimeFact">-->
        <!--            <el-date-picker v-model="search.startTimeFact" type="date" placeholder="请选择 实际开始时间"-->
        <!--                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <!--        <el-col :span="6">-->
        <!--          <el-form-item label="实际结束时间" prop="endTimeFact">-->
        <!--            <el-date-picker v-model="search.endTimeFact" type="date" placeholder="请选择 实际结束时间"-->
        <!--                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="用户账号"
                        prop="userNo">
            <el-input
              readonly
              clearable
              v-model="form.userNo"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户姓名"
                        prop="realName">
            <el-input
              readonly
              clearable
              v-model="form.realName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="角色" prop="userNo">
            <el-input
              readonly
              clearable
              v-model="sysRoleVO.roleName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到期时间"
                        prop="expiryDate">
            <el-input
              readonly
              clearable
              v-model="form.expiryDate"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="性别"
            prop="sex"
          >
            <el-radio-group v-model="form.sex" disabled>
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="状态"
            prop="state"
          >
            <el-switch v-model="form.state" active-text="启用" inactive-text="禁用" :active-value="1" disabled
                       :inactive-value="2" active-color="#00ffc6">
            </el-switch>

          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="所属区域"
            prop="telPhone"
          >
            <el-input
              readonly
              clearable
              type="tel"
              autocomplete="off"
              v-model="sysRegionVO.name"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="移动电话"
            prop="telPhone"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.telPhone"
              readonly
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="备注"
            prop="msg"
          >
            <el-input
              type="textarea"
              clearable
              autocomplete="off"
              v-model="form.msg"
              readonly
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getuserInfo } from "@/api/userinfo";
import { onMounted, reactive, toRefs } from "vue";

export default {
  name: "userinfo",
  setup() {
    let option = reactive({
      form: {},
      labelWidth: "120px",
      sysRoleVO: {},
      sysRegionVO: {}
    });
    onMounted(() => {
      getuserInfo().then(res => {
        if (res.data.success) {
          // console.log(res);
          // this.$nextTick(()=>{
          option.form = res.data.data;
          option.sysRoleVO = res.data.data.sysRoleVO;
          option.sysRegionVO = res.data.data.sysRegionVO;
          // })
        }
      });
    });
    return {
      ...toRefs(option)
    };
  }
};
</script>

<style scoped>
.box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.innerForm {
  width: 60%;
}
</style>